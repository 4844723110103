import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static get targets() {
    return ['sidebarContainer', 'icon', 'link', 'sidebarBody', 'filterIcon'];
  }

  toggle() {
    this.mainElement = document.querySelector('main');
    this.searchContent = document.querySelector('#search-content');

    if (this.sidebarContainerTarget.dataset.expanded === '1') {
      this.collapse();
    } else {
      this.expand();
    }
  }

  collapse() {
    this.sidebarContainerTarget.classList.remove('sm:w-[320px]');
    this.sidebarContainerTarget.classList.add('sm:w-12');
    this.sidebarContainerTarget.dataset.expanded = '0';
    this.mainElement.classList.remove('w-[calc(100%-340px)]');
    this.mainElement.classList.add('w-[calc(100%-48px)]');
    this.searchContent.classList.remove('w-[calc(100%-20px)]');
    this.searchContent.classList.add('w-[98%]');
    this.filterIconTarget.classList.remove('hidden');
    this.sidebarBodyTarget.classList.add('hidden');

    this.iconTarget.innerHTML = `
      <div class="absolute left-8 cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="#292f7d" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM241 377c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l87-87-87-87c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L345 239c9.4 9.4 9.4 24.6 0 33.9L241 377z"/></svg>
      </div>
    `;
    this.linkTargets.forEach((link) => {
      link.classList.add('sr-only');
    });
  }

  expand() {
    this.sidebarContainerTarget.classList.add('sm:w-[320px]');
    this.sidebarContainerTarget.classList.remove('sm:w-12');
    this.sidebarContainerTarget.dataset.expanded = '1';
    this.mainElement.classList.remove('w-[calc(100%-48px)]');
    this.mainElement.classList.add('w-[calc(100%-340px)]');
    this.searchContent.classList.remove('w-[98%]');
    this.searchContent.classList.add('w-[calc(100%-20px)]');
    this.filterIconTarget.classList.add('hidden');
    this.sidebarBodyTarget.classList.remove('hidden');

    this.iconTarget.innerHTML = `
      <div class="absolute -right-2 cursor-pointer">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="#292f7d" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM271 135c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-87 87 87 87c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L167 273c-9.4-9.4-9.4-24.6 0-33.9L271 135z"/></svg>
      </div>
    `;
    this.linkTargets.forEach((link) => {
      link.classList.remove('sr-only');
    });
  }
}
